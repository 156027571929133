<template>
<div class="p7 de">
  <div class="hd">
    <span @click="$router.back()">返回</span>
     <span>留言反馈</span>
    <div class="action">
      <div>
      </div>
      <div style="display: none">
        <md-icon name="share" />
        分享
      </div>
    </div>

  </div>

  <div class="bn">

  </div>
  <div class="fm">
    <md-field>
      <md-input-item title="标题" v-model="postData.title" placeholder="填写标题" />
      <md-textarea-item
          title="留言内容"
          autosize
          placeholder="请填写内容"
          v-model="postData.content"
      />
      <md-input-item title="姓名" v-model="postData.name" placeholder="填写留言联系人" />
      <md-input-item type="phone" title="电话" v-model="postData.phone" placeholder="填写留言电话" />
      <md-input-item title="验证码" v-model="postData.code" placeholder="填写验证码" >
        <img :src="`http://wzsqyg.com/captcha?t=${t}`" @click="t=Date.now()" slot="right">
      </md-input-item>

      <md-button @click="tj" style="margin-top: 30px;">提交</md-button>
    </md-field>
  </div>

</div>
</template>
<script>

export default {
    name: "msg",
    data(){
      return {
          t:Date.now(),
         postData:{
            title:'',
           name:'',
           content:'',
           code: ''
         }
      }
    },
    created() {

    },
     methods: {
       async tj(){
         const uid = this.$store.state.userInfo.uid || '0'
         if(!this.postData.title) return this.$toast.failed('请填写留言标题！')
         if(!this.postData.content) return this.$toast.failed('请填写留言内容！')
         if(!this.postData.name) return this.$toast.failed('请填写联系人姓名！')
         if(!this.postData.phone) return this.$toast.failed('请填写联系人手机号！')
         if(!this.postData.code) return this.$toast.failed('请填写验证码！')

         const zhengze = /^1[3456789]\d{9}$/

         if(zhengze.test(this.postData.phone)){
           let res = await this.$api.post('/msg/addMsg',{ ...this.postData,uid,ismobile:1 })
           const { success,info  } = res.data;
           if(!success) return     this.$toast.info(info)
           this.$toast.succeed('感谢您的宝贵意见，我们会及时查阅')
           this.t = Date.now()
           this.postData ={
                 title:'',
                 name:'',
                 content:'',
                 code: ''
           }
         }else {
           this.$toast.failed('请填写正确的手机号码！')
         }
         }
     }
};
</script>

<style lang="scss" scoped>

  .bn{
    //height: 300px;
    //border: 1px solid #000;
  }

</style>
